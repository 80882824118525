import { DEFAULT_RAGE_THROTTLE, getNormalizedUrl } from "../utils";
import { getPerformanceData, resetPerformanceData } from "./collectPerformance";

const RECORDING_STOPPED = "recording-stopped";
const DATA_PLUG_LABEL_ATTRIBUTE_NAME = "data-plug-label";
const MAX_LABEL_LENGTH = 25;

interface pageChangeInterface {
    event_type: string, 
    pageUrl: string;
    normalizedUrl: string;
    pageName: string;
    timestamp: number;
}

export let eventLogs: Array<any> = []
let currentPageUrl: string = document.location.href, currentPageName: string = document.title;
export let lastClickArrayIndex: number
let lastClickTimestamp: number

export const resetEventData = (): void => {
    eventLogs = []
}

// Function to push page changes.
const pushPageChange = (pageUrl: string, pageName: string): void => {
    // Using the page change naming convention as exception
    const pageChange: pageChangeInterface = {
        event_type: "nav",
        pageUrl: pageUrl,
        normalizedUrl: getNormalizedUrl(pageUrl),
        pageName: pageName,
        timestamp: Date.now()
    }
    eventLogs.push(pageChange);
    currentPageUrl = pageUrl;
    currentPageName = pageName;
}

// Function to push page changes.
const pushPerfCapture = (): void => {
    const performanceData = getPerformanceData();
    const perfCapture = {
        event_type: "perf-capture",
        pageUrl: currentPageUrl,
        normalizedUrl: getNormalizedUrl(currentPageUrl),
        pageName: currentPageName,
        timestamp: Date.now(),
        performance: performanceData
    }
    eventLogs.push(perfCapture);
    resetPerformanceData();
}

//Function to push recording stopped event
export const pushRecordingStopped = (pageUrl: string, pageName: string) => {
    const recordingStopped: pageChangeInterface = {
        event_type: RECORDING_STOPPED,
        pageUrl: pageUrl,
        pageName: pageName,
        normalizedUrl: getNormalizedUrl(pageUrl),
        timestamp: Date.now()
    }
    eventLogs.push(recordingStopped);
}

/**
 * @description Get the label of the element
 * Checks for the following details of the element in the order:
 * 1. data-plug-label attribute
 * 2. label attribute
 * 3. aria-label attribute
 * 4. labelled by element's innerText (using the value of "for" or "aria-labelledby" attribute as the selector)
 * 5. placeholder attribute
 * 6. id attribute
 * 7. alt attribute
 * 8. title attribute
 * 9. name attribute
 * 10. innerText
 * 11. value
 * 12. tagName
 */
const getElementLabel = (element: HTMLElement): string | null => {
    let label = null;
    try {
        const elementId = element.getAttribute("id");

        // Check for data-plug-label, label, aria-label attributes in the order of preference
        label = element.getAttribute(DATA_PLUG_LABEL_ATTRIBUTE_NAME) || element.getAttribute("label") || element.getAttribute("aria-label");
        if (label) {
            return label;
        }

        // Check for label by element
        if (elementId) {
            const labelElement: HTMLElement | null = document.querySelector(`[for='${elementId}']`) || document.querySelector(`[aria-labelledby='${elementId}']`) as HTMLElement | null;
            if (labelElement) {
                return labelElement.innerText || element.tagName;
            }
        }

        // Check for placeholder, id, alt, title, name attributes in the order of preference
        label = element.getAttribute("placeholder") || element.getAttribute("id") || element.getAttribute("alt") || element.getAttribute("title") || element.getAttribute("name");
        if (label) {
            return label;
        }

        // No label found in attributes, use innerText, value, or tagName
        if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
            label = element.innerText || element.value || element.tagName;
        } else {
            label = element.innerText || element.tagName;
        }

        return truncate(label, MAX_LABEL_LENGTH);
    } catch (e) {
        console.warn("Error in fetching label");
        return "Unknown Element";
    }
}

const truncate = (innerText: string, length: number) =>
    innerText && innerText.length > length
        ? `${innerText.substring(0, length)}...`
        : innerText;

export const newListener = (node: HTMLElement | HTMLInputElement | null, timestamp: number, eventType: string, inputValue?: string) => {
    if(eventType == "click") {
        const eventPush: any = {
            event_type: eventType,
            target: node?.nodeName || "",
            label: (node ? getElementLabel(node) : "") || "",
            value: truncate(node?.innerText || "", 50) || "",
            timestamp: timestamp,
            epochTime: Math.floor(Date.now() / 1000),
            isDead: true,
            pageUrl: document.location.href,
            normalizedUrl: getNormalizedUrl(document.location.href),
            pageName: document.title,
        }
        if(!eventLogs.length && eventPush.hasOwnProperty('isDead')) {
            delete eventPush['isDead'];
        }
        if(lastClickTimestamp && (timestamp - lastClickTimestamp) <= DEFAULT_RAGE_THROTTLE) {
            eventPush.isRage = true
        }
        lastClickTimestamp = timestamp
        const index = eventLogs.push(eventPush);
        lastClickArrayIndex = index - 1
    }

    if(eventType == "form-change" && inputValue && inputValue !== "on") {
        const eventPush = {
            event_type: eventType,
            target: node?.nodeName || "",
            label: (node ? getElementLabel(node) : "") || "",
            value: inputValue,
            timestamp: timestamp,
            epochTime: Math.floor(Date.now() / 1000),
        }
        eventLogs.push(eventPush);
    }
}

let isPageChangeTrackingInitialized = false;

// Store original methods at module level
let originalPushState: typeof history.pushState;
let originalReplaceState: typeof history.replaceState;

export const initPageChangeTracking = () => {
    if (isPageChangeTrackingInitialized) return;

    // push first page event
    pushPageChange(document.location.href, document.title);

    try {
        // Store original methods
        originalPushState = history.pushState;
        originalReplaceState = history.replaceState;

        history.pushState = function(...args) {
            const result = originalPushState.apply(history, args);

            // push previous page's performance data
            pushPerfCapture();

            // page change event
            pushPageChange(document.location.href, document.title);
            return result;
        };

        history.replaceState = function(...args) {
            const result = originalReplaceState.apply(history, args);

            // push previous page's performance data
            pushPerfCapture();

            // page change event
            pushPageChange(document.location.href, document.title);
            return result;
        };

        isPageChangeTrackingInitialized = true;
    } catch (error) {
        console.error('Failed to initialize page change tracking:', error);
    }
}

export const uninitPageChangeTracking = () => {
    if (!isPageChangeTrackingInitialized) return;

    try {
        // Restore original methods
        history.pushState = originalPushState;
        history.replaceState = originalReplaceState;

        isPageChangeTrackingInitialized = false;
    } catch (error) {
        console.error('Failed to uninitialize SPA page change tracking:', error);
    }
}
