
interface LayoutShiftAttribution {
    node?: Node;
    previousRect: DOMRectReadOnly;
    currentRect: DOMRectReadOnly;
    navigationId?: string;
}
interface LayoutShift extends PerformanceEntry {
    value: number;
    sources: LayoutShiftAttribution[];
    hadRecentInput: boolean;
    navigationId?: string;
}

/**
 * For some unknown reason, the type of the event entry is not being picked up correctly.
 * So, we are creating a new interface for the event entry.
 */
interface EventEntry extends PerformanceEventTiming {
    interactionId: number;
}

let fcpData: number | null = null;
let lcpData: number | null = null;
let clsData = 0;
let inpData: number | null = null;
let observer: PerformanceObserver | null = null;
let clsSessionValue = 0;
let firstClsSessionEntry: LayoutShift | null = null, prevClsSessionEntry: LayoutShift | null = null;

export const getPerformanceData = (): object => {

    const performanceData: object = {
        lcp: {
            value: lcpData,
        },
        fcp: {
            value: fcpData,
        },
        cls: {
            value: clsData,
        },
        inp: {
            value: inpData,
        },
    }
    
    return performanceData;
}

export const resetPerformanceData = () => {
    fcpData = null;
    lcpData = null;
    clsData = 0;
    inpData = null;
    clsSessionValue = 0;
    firstClsSessionEntry = null;
    prevClsSessionEntry = null;
}

export const initPerformanceCapturing = () => {
    resetPerformanceData();

    observer = new PerformanceObserver((list) => {
        try {
            const entries = list.getEntries();
            entries.forEach((entry) => {
                // First Contentful Paint
                if (entry.entryType === 'paint' && entry.name === 'first-contentful-paint') {
                    /*
                    * NOTE: Not considering the activationStart time for FCP right now,
                    * as it is experimental and not supported in all browsers.
                    * Also, should ideally disconnect the observer after FCP is captured.
                    */
                    fcpData = entry.startTime;
                }

                // Cumulative Layout Shift
                if (entry.entryType === 'layout-shift') {
                    // Cast the entry to LayoutShift
                    const layoutShiftEntry = entry as LayoutShift;

                    // Only consider layout shifts that were not caused by user interactions
                    if (!layoutShiftEntry.hadRecentInput) {
                        /**
                         * A burst of layout shifts, known as a session window,
                         * is when one or more individual layout shifts occur in rapid succession
                         * with less than 1-second in between each shift
                         * and a maximum of 5 seconds for the total window duration.
                         * CLS is a measure of the largest burst of layout shift scores.
                         * (Reference: https://web.dev/articles/cls)
                         */
                        if (
                            clsSessionValue && prevClsSessionEntry && firstClsSessionEntry &&
                            layoutShiftEntry.startTime - prevClsSessionEntry.startTime < 1000 &&
                            layoutShiftEntry.startTime - firstClsSessionEntry.startTime < 5000
                        ) {
                            clsSessionValue += layoutShiftEntry.value;
                            prevClsSessionEntry = layoutShiftEntry;
                        } else {
                            clsSessionValue = layoutShiftEntry.value;
                            firstClsSessionEntry = layoutShiftEntry;
                            prevClsSessionEntry = layoutShiftEntry;
                        }

                        // Update the cumulative layout shift value if it's greater than the current value
                        if (clsSessionValue > clsData) {
                            clsData = clsSessionValue;
                        }
                    }
                }

                // Largest Contentful Paint
                if (entry.entryType === 'largest-contentful-paint') {
                    /*
                    * NOTE: Not considering the activationStart time for LCP right now,
                    * as it is experimental and not supported in all browsers.
                    */
                    lcpData = entry.startTime;
                }

                // Interaction to Next Paint
                if (entry.entryType === 'event') {
                    /**
                     * NOTE: We are not considering outliers for input delay right now.
                     * (Reference: https://web.dev/articles/inp)
                     */

                    // Typecasted to PerformanceEventTiming to access interactionId
                    const eventEntry = entry as EventEntry;

                    // We don't want to consider the input delay caused by hover events
                    // InteractionId will be 0 for events that we don't care about. eg: hover events
                    if (eventEntry.interactionId) {
                        // Considering the max value of all the input delays
                        if (inpData === null || eventEntry.duration > inpData) {
                            inpData = eventEntry.duration;
                        }
                    }
                }
            });
        } catch (error) {
            // Error in performance observer
            // Skip logging the error because since it's a performance observer
            // there can be too many errors in the console leading to performance issues
        }
    });


    // Start observing the desired performance entry types
    observer.observe({ type: 'layout-shift', buffered: true });
    observer.observe({ type: 'largest-contentful-paint', buffered: true });
    observer.observe({ type: 'event', buffered: true, durationThreshold: 0 } as PerformanceObserverInit);
    observer.observe({ type: 'paint', buffered: true });
}

export const uninitPerformanceCapturing = () => {
    observer?.disconnect();
}
